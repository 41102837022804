<template>
  <div class="uk-container">
     <!-- eslint-disable-next-line max-len -->
    <div class="uk-position-center uk-card uk-card-default uk-card-body uk-width-1-2@m uk-margin-xlarge-top">
      <h3 class="uk-card-title">Datos de Reserva</h3>
      <hr />
      <RegistroForm @procesar="procesarFormulario" />
    <div class="uk-text-center">
        <span>
          ¿Ya tienes cuenta?
          <a href=""> </a>
          <router-link to="/login">Inicia sesión</router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import RegistroForm from '../components/RegistroForm.vue';

export default {
  components: {
    RegistroForm,
  },

  methods: {
    ...mapActions(['registroUsuarios']),
    procesarFormulario(userData) {
      console.log(userData);
      this.registroUsuarios(userData);

      this.usuario = {
        name: '',
        lastname: '',
        email: '',
        password: '',
        phone: null,
      };
    },
  },
};
</script>

<style></style>
