<template>
  <div id="app">
    <div class="uk-container">
       <h1 class="uk-margin-medium-top uk-text-center">Ubicación de Kanaj</h1>
        <!-- eslint-disable-next-line max-len -->
       <GoogleMap :latitude= 23.692904246269023 :longitude= -105.1278676880078 :title="'Ubicación Kanaj'" />

    </div>
  <Footer />
  </div>
</template>

<script>
import GoogleMap from '../components/GoogleMap.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'App',
  components: {
    GoogleMap,
    Footer,
  },
};
</script>
