var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('gmap-map',{ref:"mapRef",staticStyle:{"width":"100%","height":"500px"},attrs:{"center":_vm.center,"zoom":9}},[_vm._l((_vm.markers),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":m.position,"title":m.title,"clickable":true,"draggable":false,"icon":m.icon},on:{"click":function($event){return _vm.openWindow(index)}}})}),_c('gmap-polyline',{attrs:{"path":_vm.path,"options":{ strokeColor:'#008000',}},on:{"update:path":function($event){_vm.path=$event},"click":_vm.openDriveTo}}),_c('gmap-info-window',{attrs:{"opened":_vm.kanaj_window,"position":_vm.markers[0].position,"options":{
        pixelOffset: {
          width: 0,
          height: -35
        }
      }},on:{"closeclick":function($event){_vm.kanaj_window = false}}},[_c('div',{staticClass:"uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin-remove",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-card-media-left uk-cover-container"},[_c('img',{attrs:{"src":"https://kanaj.s3.us-east-2.amazonaws.com/logo.92caa6bb.jpg","alt":"no-image","uk-cover":""}}),_c('canvas',{attrs:{"width":"600","height":"400"}})]),_c('div',[_c('div',{staticClass:"uk-card-body"},[_c('h3',{staticClass:"uk-card-title"},[_vm._v("KANAJ BUBBLE GLAMPING DURANGO")]),_c('hr'),_c('p',[_vm._v(" Camino a Regocijo Kilómetro 12 frente a Noram de México, Acceso por carretera libre Durango a Mazatlán Km 60 (Navíos) ")]),_c('hr'),_c('a',{attrs:{"target":"_blank","href":_vm.buildUrl}},[_vm._v("Abrir en Google Maps")])])])])]),_c('gmap-info-window',{attrs:{"opened":_vm.youarein_window,"position":_vm.markers[1].position,"options":{
        pixelOffset: {
          width: 0,
          height: -35
        }
      }},on:{"closeclick":function($event){_vm.youarein_window = false}}},[_c('div',{staticClass:"uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin-remove",attrs:{"uk-grid":""}},[_c('p',[_vm._v("Tu estas aqui.")])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }