<template>

  <div>
    <div class="uk-container">
      <!-- {{rooms.type}} -->
      <!-- <h1>{{ $route.query }}</h1> -->
      <!-- eslint-disable-next-line max-len -->

      <h2 class="uk-heading-bullet uk-margin-medium-top uk-margin-medium-bottom"
        v-show="inicio != '--' || fin != '--'">
        Lugares Disponibles del {{inicio}} al {{fin}}
      </h2>

      <div v-show="therAreRooms">
        <img src="@/assets/no-result.png" alt="" class="uk-image not-results">
        <h2>
          <center>
            No se encontraron lugares disponibles con la fecha seleccionada.

            <br>
            <router-link
              :to="{name: 'Home'}"
              class="uk-button app-bg uk-margin-top uk-margin-buttom block-inline"
              custom v-slot="{ navigate }"
            >
               <!-- eslint-disable-next-line max-len -->
              <span @click="navigate" >{{ inicio == '--' ? 'Seleccionar una fecha': 'Seleccionar otra fecha' }}</span>
            </router-link>

          </center>

        </h2>
      </div>

      <!-- eslint-disable-next-line max-len -->
      <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid v-for="item in rooms" :key="item.id">

        <div class="uk-card-media-left uk-cover-container">
          <img :src="buildImageUrl(item.attributes.cover)" alt="" uk-cover />
          <canvas max-width="600" max-height="400"></canvas>
        </div>

        <div>
          <div class="uk-card-header">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
              <div class="uk-width-expand">
                <h3 class="uk-card-title">{{ item.attributes.lote }}</h3>
              </div>
            </div>
          </div>
          <div class="uk-card-body">
            <div v-html="item.attributes.details">
            </div>
            <p>
            </p>
          </div>
          <div class="uk-card-footer">

            <span class='price-day'>
              <i class="fas fa-users"></i> {{ item.attributes.capacity }} Persona{{ item.attributes.capacity > 1 ? 's' : '' }} de capacidad
              <br>
              <i class="fas fa-dollar-sign"></i>
              {{ formatPrice(item.attributes.amount) }} / por dia
            </span>

            <!-- eslint-disable-next-line max-len -->
            <router-link
              :to="{
                  name: 'Checkout',
                  query: {
                    id: item.attributes.id,
                    type: item.type,
                    start: $route.query.fecha,
                    end: $route.query.fecha2,
                  }
                }"
              custom v-slot="{ navigate }"
              class="uk-button app-bg uk-align-right uk-margin-small-top"
            >
            <span @click="navigate" @keypress.enter="navigate" role="link">Reservar</span>
            </router-link>
          </div>
        </div>

      </div>
    </div>
    <PetFriendly />
    <Footer />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Footer from '../components/Footer.vue';
// eslint-disable-next-line import/extensions
import { SERVER_HOST } from '../App';
import PetFriendly from '../components/PetFriendly.vue';

export default {
  components: {
    Footer,
    PetFriendly,
  },
  computed: {
    ...mapState(['rooms']),
    inicio() {
      return this.formatDate(this.$route.query.fecha);
    },
    fin() {
      return this.formatDate(this.$route.query.fecha2);
    },
    therAreRooms() {
      // eslint-disable-next-line eqeqeq
      return this.rooms == null || this.rooms.length == 0;
    },
  },
  methods: {
    ...mapActions(['pintarRoom']),
    buildImageUrl(url) {
      return SERVER_HOST + url;
    },
    formatDate(dateStr) {
      if (dateStr == null || typeof (dateStr) === 'undefined') return '--';
      const splt = dateStr.split('-');
      return (`${splt[2]}/${splt[1]}/${splt[0]}`);
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },

  created() {
    this.pintarRoom({
      fecha: this.$route.query.fecha,
      fecha2: this.$route.query.fecha2,
    });
  },
};
</script>

<style>

  .price-day{
    color: #1b4225;
    font-weight: bold;
  }

 div li:hover,
 div li.router-link-active,
 div li.router-link-exact-active {
   background-color: none;
   cursor: pointer;
 }

 .not-results{
   max-width: 280px;
   height: auto;
   display: block;
   margin: 0 auto;
 }

</style>
