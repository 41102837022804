<template>
  <div class="uk-container">
    <div class="uk-position-center uk-card uk-card-default uk-card-body uk-width-1-2@m">
      <h3 class="uk-card-title">Restablecer Contraseña</h3>
      <hr />
      <form @submit.prevent="enviarPass" class="uk-form-stacked">
        <div class="uk-margin">
          <!-- eslint-disable-next-line max-len -->
          <label class="uk-form-label uk-text-left" for="form-stacked-email"
            ><span class="uk-text-danger" v-if="!$v.forgetPass.email.required">*</span> Escribe tu
            email registrado</label
          >
          <div class="uk-form-controls">
            <input
              class="uk-input uk-form-width-large"
              :class="{ 'uk-form-danger': $v.forgetPass.email.$error }"
              id="form-stacked-email"
              type="email"
              placeholder=""
              v-model.trim="$v.forgetPass.email.$model"
            />
            <div class="uk-alert-danger" uk-alert v-if="!$v.forgetPass.email.email">
              <span>Debe ingresar un email válido</span>
            </div>
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <small class="uk-text-danger" v-if="$v.$invalid">Los campos con * son obligatorios</small>
        </div>
        <!-- <div class="uk-alert-danger" uk-alert v-for="item in errors" :key="item.id">
          <span>{{ item.message }}</span>
        </div>

        <div class="uk-alert-success" uk-alert v-for="item in success" :key="item.id">
          <p>
            {{item}}
          </p>
        </div> -->
        <div class="uk-alert-danger uk-text-center" uk-alert v-if="errors.tipo !== null">
          <a class="uk-alert-close" uk-close></a>
          <span>
            {{ errors.mensaje }}
          </span>
        </div>
        <!-- eslint-disable-next-line max-len -->
        <button
          class="uk-button uk-align-center app-bg uk-margin-small-top uk-margin-small-bottom"
          :disabled="$v.$invalid"
        >
          Restablecer Contraseña
        </button>
        <div></div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  required,
  email,
} from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      forgetPass: {
        email: '',
      },
    };
  },
  computed: {
    ...mapState(['errors', 'success']),
  },

  validations: {
    forgetPass: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions(['recoverPass']),
    enviarPass() {
      console.log(this.forgetPass);
      console.log('submit!');
      this.$v.$touch();
      if (this.$v.$invalid) {
        // this.submitStatus = 'ERROR';
        console.log('Se generó un error');
      } else {
        console.log('Se enviaron los datos Correctamente!');
        this.recoverPass(this.forgetPass);
      }
    },
  },
};
</script>

<style></style>
