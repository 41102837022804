<template>
  <div>
    <gmap-map :center="center" :zoom="9" style="width: 100%; height: 500px" ref="mapRef">
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :title="m.title"
        :clickable="true"
        :draggable="false"
        @click="openWindow(index)"
        :icon="m.icon"
      ></gmap-marker>

      <gmap-polyline v-bind:path.sync="path" v-bind:options="{ strokeColor:'#008000',}" @click="openDriveTo"></gmap-polyline>

      <gmap-info-window
        @closeclick="kanaj_window = false"
        :opened="kanaj_window"
        :position="markers[0].position"
        :options="{
          pixelOffset: {
            width: 0,
            height: -35
          }
        }"
      >
        <!-- <div class="uk-card uk-card-default uk-card-body uk-width-1-2@m uk-margin-large-left">
          <h3 class="uk-card-title">Default</h3> -->
        <!-- eslint-disable-next-line max-len -->
        <!-- <p>Lorem ipsum <a href="#">dolor</a> sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div> -->
        <div
          class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin-remove"
          uk-grid
        >
          <div class="uk-card-media-left uk-cover-container">
            <img src="https://kanaj.s3.us-east-2.amazonaws.com/logo.92caa6bb.jpg" alt="no-image" uk-cover />
            <canvas width="600" height="400"></canvas>
          </div>
          <div>
            <div class="uk-card-body">
              <h3 class="uk-card-title">KANAJ BUBBLE GLAMPING DURANGO</h3>
              <hr>
              <p>
                Camino a Regocijo Kilómetro 12 frente a Noram de México, Acceso por carretera libre
                Durango a Mazatlán Km 60 (Navíos)
              </p>
              <hr>
              <a target="_blank" :href="buildUrl">Abrir en Google Maps</a>
            </div>
          </div>
        </div>
      </gmap-info-window>

      <gmap-info-window
        @closeclick="youarein_window = false"
        :opened="youarein_window"
        :position="markers[1].position"
        :options="{
          pixelOffset: {
            width: 0,
            height: -35
          }
        }"
      >
        <div
          class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin-remove"
          uk-grid
        >
          <p>Tu estas aqui.</p>
        </div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>
<script>

import { gmapApi } from 'vue2-google-maps';

export default {
  props: {
    latitude: Number,
    longitude: Number,
    title: String,
  },

  computed: {
    google: gmapApi,
    buildUrl() {
      return `https://www.google.com/maps/dir/?api=1&origin=${this.markers[1].position.lat},${this.markers[1].position.lng}&destination=23.692904246269023,-105.1278676880078&travelmode=driving`;
    },
  },

  data() {
    return {
      center: { lat: 23.692904246269023, lng: -105.1278676880078 },
      markers: [
        {
          position: { lat: 23.692904246269023, lng: -105.1278676880078 },
          title: 'Ubicación Kanaj',
        },
        {
          position: { lat: 23.692904246269023, lng: -105.1278676880078 },
          title: 'Tú Ubicación',
          icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
        },
      ],
      info_marker: null,
      infowindow: { lat: this.latitude, lng: this.longitude },
      kanaj_window: true,
      youarein_window: false,
      path: [],
    };
  },  

  methods: {

    openDriveTo() {
      window.open(this.buildUrl);
    },

    getDirections() {
      const directionsService = new this.google.maps.DirectionsService();
      const directionsRenderer = new this.google.maps.DirectionsRenderer();
      directionsRenderer.setMap(this.$refs.mapRef);

      directionsService.route(
        {
          origin: this.markers[1].position,
          destination: this.markers[0].position,
          travelMode: this.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            console.log('directions response', response);
            directionsRenderer.setDirections(response);
            this.path = response.routes[0].overview_path;
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      )},

    openWindow(index) {
      switch (index) {
        case 0:
          this.kanaj_window = true;
          this.youarein_window = false;
          break;
        case 1:
          this.youarein_window = true;
          this.kanaj_window = false;
          break;
        default:
          this.kanaj_window = false;
          this.youarein_window = false;
          break;
      }
    },
  },

  created() {
    this.$getLocation({})
      .then((coordinates) => {
        this.markers[1].position = coordinates;
        this.getDirections();
        // console.log(this.markers);
      }).catch(() => {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Por favor, habilita la localización en tu dispositivo',
          // footer: '<a href>Why do I have this issue?</a>',
        });
      });
  },
};
</script>
