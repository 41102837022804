<template>
<!-- eslint-disable max-len  -->
<div class="uk-container">
  <div class="uk-margin-xlarge-top" v-show="reservations.length == 0 || reservations === null">
    <img src="@/assets/no-result.png" alt="" class="uk-image not-results uk-align-center">
    <h2>
    <center>
            No Se Encontraron Reservaciones.

            <br>
            <router-link
              :to="{name: 'Home'}"
              class="uk-button app-bg uk-margin-top uk-margin-buttom block-inline"
              custom v-slot="{ navigate }"
            >
              <!-- eslint-disable-next-line max-len -->
              <span @click="navigate" >Hacer Una Reservación</span>
            </router-link>

          </center>

        </h2>
  </div>
  <!-- {{reservations}} -->
    <div class="uk-card uk-margin-medium-top" v-for="(item, index) in reservations" :key="index.id">
      <!-- {{index}} - {{item}} -->

      <div class="uk-card-header background uk-margin-remove-bottom">
        <h3 class="uk-card-title tittle">Folio: #{{ item.id }}</h3>
        <h3 class="uk-card-title tittle uk-align-right">Total: {{ formatPrice(item.attributes.amount) }}</h3>
      </div>
      <div
        class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin uk-margin-remove-top"
        uk-grid
      >
      <div class="uk-card-media-left uk-cover-container">
        <img :src="buildImageUrl(item.attributes.cover)" alt="Cabañas" uk-cover />
        <canvas width="600" height="400"></canvas>
      </div>
      <div>
        <div class="uk-card-body">
          <div class="uk-grid-divider uk-child-width-expand@s" uk-grid>
            <div>
              <h3 class="uk-card-title">Cabaña A Color Roja</h3>
                <p>
                  <strong>Descripción:</strong>
                </p>

                <p>{{item.attributes.reservable.details}}</p>
            </div>
            <div>
              <h3 class="uk-card-title">Días De Reserva</h3>
              <p>
                <ul v-for="item2 in item.attributes.reservations" :key="item2.id">
                  <li>{{item2.start_date}} </li>
                </ul>
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="uk-align-center">
    <button
        class="uk-button uk-align-center app-bg uk-margin-small-top uk-margin-small-bottom"
        v-if="pages !== null"
        @click="getReservationUpdated"
      >
        Mostrar más
      </button>
  </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SERVER_HOST } from '../App';

export default {
  watch: {
  },
  computed: {
    ...mapState(['reservations', 'pages']),

  },

  methods: {
    ...mapActions(['getReservation']),
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.');
      return `$ ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
    buildImageUrl(url){
      console.log(SERVER_HOST)
      console.log(url)
      return SERVER_HOST+url
    },

    getReservationUpdated() {
      this.getReservation({
        url: this.pages,
      });
    },
  },

  created() {
    this.getReservation();
  },
};
</script>

<style>
.tittle {
  color: black;
}

h3 {
  display: inline-block;
}

.background {
  background: #bbbbbb;
}
</style>
