/* eslint-disable max-len */ /* eslint-disable no-undef */
<template>
  <div class="">
    <div class="center-container">
      <div class="uk-alert-success uk-width-1-1" uk-alert>
        <div v-if="$route.query.payment_method == 'TRANSFER'" class="uk-alert-success" uk-alert>
          <h3>
            <span uk-icon="check"></span>
            <!-- eslint-disable-next-line max-len -->
            TU RESERVACIÓN SE HA HECHO DE MANERA EXITOSA, LO QUE SIGUE ES DEPOSITAR EN LA SIGUIENTE
            CUENTA BANCARIA
          </h3>

          <strong>Noches: </strong>

          <span class="d-block" v-for="i in reservableDay" v-bind:key="i.id">
            {{ i.attributes.start }}
          </span>

          <hr />

          <div>
            <span class="d-block"> <strong> CLABE: 002190468400439912 </strong> </span>
            <span class="d-block"> <strong> BANCO: BANCOMER </strong> </span>
            <span class="d-block"> <strong> TITULAR: ALEJANDRO MARTINEZ RUIZ </strong> </span>
          </div>
        </div>

        <div v-if="$route.query.payment_method == 'CARD'" class="uk-alert-success" uk-alert>
          <h3>
            <span uk-icon="check"></span>
            TU RESERVACIÓN SE HA HECHO DE MANERA EXITOSA
          </h3>

          <strong>Noches: </strong>

          <span class="d-block" v-for="i in reservableDay" v-bind:key="i.id">
            {{ i.attributes.start }}
          </span>

          <hr />
        </div>

        <div v-if="$route.query.payment_method == 'OXXO'" class="uk-alert-success" uk-alert>
          <h3>
            <span uk-icon="check"></span>
            <!-- eslint-disable-next-line max-len -->
            TU RESERVACION SE HA HECHO DE MANERA EXITOSA, LO QUE SIGUE ES ACUDIR A TU OXXO MAS
            CERCANO A REALIZAR EL PAGO
          </h3>

          <strong>Noches: </strong>

          <span class="d-block" v-for="i in reservableDay" v-bind:key="i.id">
            {{ i.attributes.start }}
          </span>

          <hr />

          <div class="oxxo-container uk-visible@m">
            <html>
              <head>
                <link href="styles.css" media="all" rel="stylesheet" type="text/css" />
              </head>
              <body>
                <div class="opps">
                  <div class="opps-header">
                    <div class="opps-reminder">Ficha digital. No es necesario imprimir.</div>
                    <div class="opps-info">
                      <!-- eslint-disable-next-line max-len -->
                      <div class="opps-brand">
                        <img src="../assets/oxxopay_brand.png" alt="OXXOPay" />
                      </div>
                      <div class="opps-ammount">
                        <h3>Monto a pagar</h3>
                        <h2>$ {{ $route.query.amount }} <sup>MXN</sup></h2>
                        <!-- eslint-disable-next-line max-len -->
                        <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
                      </div>
                    </div>
                    <div class="opps-reference">
                      <h3>Referencia</h3>
                      <h1>{{ $route.query.reference }}</h1>
                    </div>
                  </div>
                  <div class="opps-instructions">
                    <h3>Instrucciones</h3>
                    <ol>
                      <li>
                        Acude a la tienda OXXO más cercana.
                        <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank"
                          >Encuéntrala aquí</a
                        >
                      </li>
                      <!-- eslint-disable-next-line max-len -->
                      <li>
                        Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.
                      </li>
                      <!-- eslint-disable-next-line max-len -->
                      <li>
                        Dicta al cajero el número de referencia en esta ficha para que tecleé
                        directamete en la pantalla de venta.
                      </li>
                      <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                      <!-- eslint-disable-next-line max-len -->
                      <li>
                        Al confirmar tu pago, el cajero te entregará un comprobante impreso.
                        <strong>En el podrás verificar que se haya realizado correctamente.</strong>
                        Conserva este comprobante de pago.
                      </li>
                    </ol>
                    <!-- eslint-disable-next-line max-len -->
                    <div class="opps-footnote">
                      Al completar estos pasos recibirás un correo de
                      <strong>Kanaj.mx</strong> confirmando tu pago.
                    </div>
                  </div>
                </div>
              </body>
            </html>
          </div>

          <div class="uk-hidden@m black-text">
            <!-- eslint-disable-next-line max-len -->
            <div><img src="../assets/oxxopay_brand.png" alt="OXXOPay" /></div>
            <div>
              <h3>Monto A Pagar</h3>
              <h2>$ {{ $route.query.amount }} <sup>MXN</sup></h2>
              <!-- eslint-disable-next-line max-len -->
              <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
              <div>
                <h3>Referencia</h3>
                <strong style="font-size: 1.6em; color: black">{{ $route.query.reference }}</strong>
              </div>
            </div>
            <div class="opps-instructions">
              <h3>Instrucciones</h3>
              <ol>
                <li>
                  Acude a la tienda OXXO más cercana.
                  <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank"
                    >Encuéntrala aquí</a
                  >.
                </li>
                <!-- eslint-disable-next-line max-len -->
                <li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
                <!-- eslint-disable-next-line max-len -->
                <li>
                  Dicta al cajero el número de referencia en esta ficha para que tecleé directamete
                  en la pantalla de venta.
                </li>
                <li>Realiza el pago correspondiente con dinero en efectivo.</li>
                <!-- eslint-disable-next-line max-len -->
                <li>
                  Al confirmar tu pago, el cajero te entregará un comprobante impreso.
                  <strong>En el podrás verificar que se haya realizado correctamente.</strong>
                  Conserva este comprobante de pago.
                </li>
              </ol>
              <!-- eslint-disable-next-line max-len -->
              <div class="opps-footnote">
                Al completar estos pasos recibirás un correo de
                <strong>Kanaj.mx</strong> confirmando tu pago.
              </div>
            </div>
          </div>
        </div>

        <!-- eslint-disable-next-line max-len -->
        <div class="uk-button uk-button-primary ap-bg uk-card" v-if="$route.query.payment_method == 'OXXO'"  @click="printOXXO">
          <span uk-icon="icon: calendar; ratio: 3.5" class="large-icon block"></span>
          <div>Imprimir</div>
        </div>
      </div>
    </div>

    <center>
      <div class="uk-button uk-button-primary ap-bg uk-card" @click="requestGoogleCalendar()">
        <span uk-icon="icon: calendar; ratio: 3.5" class="large-icon block"></span>
        <span class="large-text block">Registrar en Google Calendar</span>
      </div>
    </center>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// eslint-disable-next-line no-unused-vars
import moment from 'moment-timezone';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(['reservableDay']),
  },
  methods: {
    requestGoogleCalendar(event) {
      // eslint-disable-next-line no-undef
      authGoogleUser(event);
    },
    // eslint-disable-next-line no-unused-vars
    printOXXO(elem) {
      // eslint-disable-next-line no-undef
      printJS({ printable: document.querySelector('.oxxo-container').innerHTML, type: 'raw-html' });
    },
  },
  created() {},
};
</script>

<style>
.uk-button-secondary {
  color: #fff;
}
.d-block {
  position: relative;
  display: block;
}
.center-container {
  display: block !important;
  margin: 35px;
  text-align: center;
}

.opps {
  width: 496px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 45px;
  margin: 40px auto;
  overflow: hidden;
  border: 1px solid #b0afb5;
  color: #4f5365;
}

.opps-reminder {
  position: relative;
  top: -1px;
  padding: 9px 0 10px;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  background: #000000;
}

.opps-info {
  margin-top: 26px;
  position: relative;
}

.opps-info:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.opps-brand {
  width: 45%;
  float: left;
}

.opps-brand img {
  max-width: 150px;
  margin-top: 2px;
}

.opps-ammount {
  width: 55%;
  float: right;
}

.opps-ammount h2 {
  font-size: 36px;
  color: #000000;
  line-height: 24px;
  margin-bottom: 15px;
}

.opps-ammount h2 sup {
  font-size: 16px;
  position: relative;
  top: -2px;
}

.opps-ammount p {
  font-size: 10px;
  line-height: 14px;
}

.opps-reference {
  margin-top: 14px;
}

h1 {
  font-size: 27px;
  color: #000000;
  text-align: center;
  margin-top: -1px;
  padding: 6px 0 7px;
  border: 1px solid #b0afb5;
  border-radius: 4px;
  background: #f8f9fa;
}

.opps-instructions {
  margin: 32px -45px 0;
  padding: 32px 45px 45px;
  border-top: 1px solid #b0afb5;
  background: #f8f9fa;
}
.opps-footnote {
  margin-top: 22px;
  padding: 22px 20 24px;
  color: #108f30;
  text-align: center;
  border: 1px solid #108f30;
  border-radius: 4px;
  background: #ffffff;
}
.black-text{
  color: black;
}
</style>
