<template>
  <!-- eslint-disable vue/return-in-computed-property -->
  <!-- eslint-disable vue/valid-v-model  -->
  <!--  eslint-disable no-undef  -->
  <!-- eslint-disable no-unused-vars -->
  <!-- eslint-disable max-len  -->
  <!-- eslint-disable no-undef  -->
  <div>
    <!-- <h1>{{ $route.query }}</h1> -->

    <div class="uk-card uk-card-default uk-card-body uk-width-1-1@m">

      <div class="uk-container">

        <div>
          <img src="../assets/bl-pet-firendly.png" class="pet-friendly-chk uk-visible@m"/>
        </div>

        <h2 class="uk-card-title">Resumen</h2>
        <!--  eslint-disable-next-line max-len -->
        <table
          class="uk-table uk-table-hover uk-table-divider uk-margin-medium-top uk-margin-medium-bottom"
        >
          <tbody>
            <tr v-for="item in reservableDay" :key="item.id">
              <td>1 Día</td>
              <td>{{ formatDate(item.attributes.start) }}</td>
              <td>$ {{ formatPrice(item.attributes.reservable_amount) }}</td>
            </tr>
            <tr>
              <td></td>
              <td>Total</td>
              <td>
                <strong> $ {{ monto }} MXN </strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="uk-alert-danger uk-text-center"
          uk-alert
          v-show="diferenciaDias()"
        >
          <p>
            {{ diferenciaDias() }}
          </p>
        </div>
      </div>

      <div class="uk-hidden@m">
          <img src="../assets/bl-pet-firendly.png" class="pet-friendly-bml-chk"/>
        </div>
        

      <h2 class="uk-card-title">Datos de Reserva</h2>

      <form
        class="uk-form-stacked uk-grid-small"
        @submit.prevent="procesarTarjeta"
        uk-grid
      >
        <span class="card-errors"></span>

        <!-- Nombre -->
        <div class="uk-margin uk-width-1-1">
          <label class="uk-form-label" for="form-stacked-text">
            <span
              class="uk-text-danger"
              v-if="!$v.card.nameUser.required || !$v.card.nameUser.isNameValid"
            >
              *
            </span>
            Nombre Completo
          </label>

          <div class="uk-form-controls">
            <input
              class="uk-input"
              id="form-stacked-nameUser"
              type="text"
              :readonly="usuario != null"
              v-model="
                usuario
                  ? ($v.card.nameUser.$model = fullName)
                  : $v.card.nameUser.$model
              "
              :class="{ 'uk-form-danger': $v.card.nameUser.$error }"
              maxlength="120"
            />
            <span class="uk-text-danger" v-if="!$v.card.nameUser.isNameValid">
              Sólo Se Aceptan Letras
            </span>
          </div>
        </div>

        <!-- Correo Electronico -->
        <div class="uk-margin uk-width-1-1">
          <label class="uk-form-label" for="form-stacked-text">
            <span class="uk-text-danger" v-if="!$v.card.email.required">
              *
            </span>
            Correo Electrónico
          </label>
          <div class="uk-form-controls">
            <input
              class="uk-input"
              id="form-stacked-email"
              type="email"
              v-model="
                usuario
                  ? ($v.card.email.$model = usuario.user_email)
                  : $v.card.email.$model
              "
              :class="{ 'uk-form-danger': $v.card.email.$error }"
              maxlength="120"
            />
          </div>

          <div class="uk-margin uk-width-1-1">
            <label class="uk-form-label" for="form-stacked-text">
              <span class="uk-text-danger" v-if="!validatePhone.isValid">
              *
            </span>
              Teléfono
            </label>

            <div class="uk-form-controls">
              <VuePhoneNumberInput
                :default-country-code="usuario ? usuario.code_country : 'MX'"
                :no-country-selector="validatePhone.countryCode === null"
                :readonly="usuario != null"
                @update="dataPhone"
                v-model="tmp_number"
                :error="!validatePhone.isValid"
                :translations="{
                  countrySelectorLabel: 'CÓDIGO DE PAÍS',
                  countrySelectorError: 'ELIGE UN PAÍS',
                  phoneNumberLabel: 'NÚMERO DE TELÉFONO',
                  example: 'EJEMPLO:',
                }"
              />
              <!-- {{$v.card.phone}}
              {{validatePhone}}
              <p>{{usuario}}</p> -->
            </div>
          </div>

          <div
            class="uk-alert-warning uk-text-center"
            uk-alert
            v-if="monto >= 10000"
          >
            <p>
              Para montos mayores a 10,000 el pago por oxxo no esta disponible,
              puedes reservar los días por separado y hacer varios depósitos.
            </p>
          </div>
          <div
            class="uk-alert-danger uk-text-center"
            uk-alert
            v-if="monto >= 100000"
          >
            <p>Lo Sentimos El Monto Máximo Debe De Ser Menor A $100,000</p>
          </div>
        </div>

        <h2 class="uk-card-title">Método de Pago</h2>

        <div class="uk-width-1-1" uk-grid>
          <div
            v-show="monto <= 100000"
            class="uk-width-1-1"
            @click="setPaymentMethod('CARD')"
          >
            <div
              class="payment-method"
              :class="payment_method == 'CARD' ? 'selected' : ''"
            >
              <h4>
                <i uk-icon="icon:  credit-card"></i> Tarjeta Débito/Crédito
              </h4>
            </div>
          </div>

          <div
            v-show="monto <= 10000"
            class="uk-width-1-1"
            @click="setPaymentMethod('OXXO')"
          >
            <div
              class="payment-method"
              :class="payment_method == 'OXXO' ? 'selected' : ''"
            >
              <h4><i class="far fa-money-bill-alt"></i> OXXO</h4>
            </div>
          </div>

          <div
            class="uk-width-1-3"
            @click="setPaymentMethod('TRANSFER')"
            v-if="false"
          >
            <div
              class="payment-method"
              :class="payment_method == 'TRANSFER' ? 'selected' : ''"
            >
              <h4><i uk-icon="icon:  arrow-right"></i> Transferencia</h4>
            </div>
          </div>
        </div>

        <div
          v-if="payment_method == 'CARD'"
          class="uk-animation-fade payment-content uk-width-1-1"
          uk-grid
        >
          <i> <span uk-icon="icon: info"></span> Ingresa tus datos de pago.</i>
          <div class="uk-margin uk-width-1-1">
            <label class="uk-form-label" for="form-stacked-text">
              <span
                class="uk-text-danger"
                v-if="!$v.card.name.required || !$v.card.name.isNameValid"
              >
                *
              </span>
              Nombre en la tarjeta
            </label>
            <div class="uk-form-controls">
              <input
                class="uk-input"
                id="form-stacked-name"
                type="text"
                v-model="$v.card.name.$model"
                :class="{ 'uk-form-danger': $v.card.name.$error }"
                maxlength="120"
              />
              <span class="uk-text-danger" v-if="!$v.card.name.isNameValid">
                Sólo se aceptan letras
              </span>
            </div>
          </div>
          <div class="uk-margin uk-width-1-1">
            <label class="uk-form-label" for="form-stacked-text">
              <span class="uk-text-danger" v-if="!$v.card.number.required">
                *
              </span>
              Número de tarjeta de crédito
            </label>
            <div class="uk-form-controls">
              <input
                class="uk-input"
                id="form-stacked-card"
                type="text"
                v-model="$v.card.number.$model"
                v-mask="'#### #### #### ####'"
              />
              <div>
                <span class="uk-text-danger" v-if="!$v.card.number.minLength">
                  Mínimo 16 caracteres
                </span>
              </div>
            </div>
          </div>

          <div class="uk-margin uk-width-2-3">
            <label class="uk-form-label" for="form-stacked-text">
              <span
                class="uk-text-danger"
                v-if="!$v.card.exp_month.required || !$v.card.exp_year.required"
              >
                *
              </span>
              Fecha de expiración
            </label>
            <div class="uk-form-controls">
              <!--  eslint-disable-next-line max-len -->
              <select
                class="uk-input uk-form-width-xsmall"
                v-model="$v.card.exp_month.$model"
                :class="{ 'uk-form-danger': $v.card.exp_month.$error }"
              >
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <span> / </span>
              <!--  eslint-disable-next-line max-len -->
              <select
                class="uk-input uk-form-width-xsmall"
                v-model.lazy="$v.card.exp_year.$model"
                :class="{ 'uk-form-danger': $v.card.exp_year.$error }"
              >
                <option
                  :value="y[0]"
                  v-for="y in expirate_years"
                  v-bind:key="y[0]"
                >
                  {{ y[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="uk-margin uk-width-1-3">
            <label class="uk-form-label" for="form-stacked-text">
              <span class="uk-text-danger" v-if="!$v.card.cvc.required">
                *
              </span>
              CVC
            </label>
            <div class="uk-form-controls">
              <input
                class="uk-input"
                id="form-stacked-cvc"
                type="text"
                v-model="$v.card.cvc.$model"
                :class="{ 'uk-form-danger': $v.card.cvc.$error }"
                maxlength="4"
              />
            </div>
          </div>
        </div>

        <div
          v-if="payment_method == 'OXXO'"
          class="uk-animation-fade payment-content uk-width-1-1"
        >
          <i>
            <span uk-icon="icon: info"></span> Se generará una referencia con la
            cuál podrás ir a pagar al OXXO mas cercano, OXXO cobrará una
            comisión adicional a tu pago.</i
          >
        </div>

        <div
          v-if="payment_method == 'TRANSFER'"
          class="uk-animation-fade payment-content uk-width-1-1"
        >
          <div class="uk-margin uk-width-1-1">
            <i>
              <span uk-icon="icon: info"></span> Realiza tu pago por medio de
              transferencia bancaria.</i
            >
          </div>
        </div>

        <div class="uk-margin uk-width-1-1 center mt-10">
          <label>
            <input
              class="uk-checkbox"
              type="checkbox"
              v-model="$v.terms.$model"
            />
            <router-link
              target="_blank"
              to="/terminos-condiciones"
              class="qut uk-margin-small-left"
            >
              Acepto las condiciones del servicio
            </router-link>
          </label>
          <br />
          <label>
            <input
              class="uk-checkbox"
              type="checkbox"
              v-model="$v.rules.$model"
            />
            <a
              target="_blank"
              href="https://kanaj.s3.us-east-2.amazonaws.com/reglamento.pdf"
              class="qut uk-margin-small-left"
            >
              Acepto el reglamento
            </a>
            <div>
              Asegurate de llenar todos los campos requeridos marcados con:
              <span class="uk-text-danger">*</span>
            </div>
          </label>
        </div>

        <div
          class="uk-alert-danger uk-width-1-1"
          uk-alert
          v-for="item in error"
          :key="item.id"
        >
          <a class="uk-alert-close" uk-close></a>
          <p class="uk-text-center">
            {{ item.message_to_purchaser }}
          </p>
        </div>

        <div
          class="uk-alert-danger uk-width-1-1"
          uk-alert
          v-if="errors.tipo !== null"
        >
          <a class="uk-alert-close" uk-close></a>
          <p class="uk-text-center">
            {{ errors.mensaje }}
          </p>
        </div>
        <!--  eslint-disable-next-line max-len -->

        <div class="uk-align-center uk-margin-medium-top">
          <button
            class="nopad uk-button"
            :class="!canNext(!$v.$invalid) || mayorMonto || !validatePhone.isValid ? '' : 'app-bg'"
            type="submit"
            :disabled="!canNext(!$v.$invalid) || mayorMonto || !validatePhone.isValid"
          >
            Pagar y Reservar
          </button>
        </div>
      </form>
    </div>

    <!-- <div
      v-if="!usuarioAutenticado"
      class="uk-card uk-card-default uk-card-body uk-width-1-1@m uk-margin-large-top"
    >
      <span>
        <span uk-icon="icon: warning"></span>
        Necesitas una cuenta con nosotros antes de proceder al pago:
        <router-link to="/registro">Crea tu cuenta en menos de 1 minuto.</router-link>
      </span>
      <hr class="uk-divider-icon" />

      <span class="uk-margin-medium-top">
        ¿Ya tienes cuenta? <router-link to="/login">Inicia Sesion.</router-link>
      </span>
    </div> -->
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    >
    </loading>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  required,
  email,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment-timezone";
// import router from '../router';
// eslint-disable-next-line import/extensions
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { CONEKTA_KEY } from "../App.vue";

export default {
  components: {
    Loading,
    VuePhoneNumberInput,
  },
  data() {
    return {
      tmp_number: null,
      validatePhone: {},
      payment_method: "",
      loading: false,
      terms: false,
      rules: false,
      monto: 99999,
      isLoading: false,
      fullPage: true,
      card: {
        nameUser: "",
        email: "",
        phone: {
          number: null
        },
        name: "",
        number: null,
        cvc: null,
        exp_month: null,
        exp_year: null,
      },
      error: [],
    };
  },
  watch: {
    usuario(val) {
      if (this.usuario != null) {
        this.tmp_number = val.phone;
      }
    },
    reservableDay(val) {
      if (val != null && typeof val !== "undefined") {
        const payAmount = val
          .map((element) => element.attributes.reservable_amount)
          .reduce((a, s) => a + s, 0);
        this.monto = payAmount;
        return this.monto;
      }
      return 99999;
    },
  },
  computed: {
    ...mapGetters([""]),
    ...mapState(["usuario", "reservableDay", "errors"]),
    expirate_years() {
      const options = [];
      for (let index = 0; index < 8; index += 1) {
        options.push([
          moment().add(index, "years").format("YY"),
          moment().add(index, "years").format("YY"),
        ]);
      }
      return options;
    },
    fullName() {
      const userName = this.usuario.user_name;
      const userLastname = this.usuario.user_lastname;

      return `${userName} ${userLastname}`;
    },
    // eslint-disable-next-line consistent-return
    mayorMonto() {
      return this.monto >= 100000;
    },
  },
  methods: {
    ...mapActions(['updateProfile', 'enviarTarjeta', 'diasReserva', 'makeReservation', 'cargarLocalStorage']),

    dataPhone({
      countryCode,
      isValid,
      phoneNumber,
      countryCallingCode,
      formattedNumber,
      formatInternational,
      nationalNumber,
    }) {
      // console.log({
      // eslint-disable-next-line max-len
      //   countryCode, isValid, phoneNumber, countryCallingCode, formattedNumber, formatInternational,
      // });

      this.validatePhone = {
        countryCode,
        isValid,
        phoneNumber,
        countryCallingCode,
        formattedNumber,
        formatInternational,
        nationalNumber,
      };
    },

    // eslint-disable-next-line consistent-return
    diferenciaDias() {
      const inicio = moment(this.$route.query.start);
      const fin = moment(this.$route.query.end);
      // console.log(fin.diff(inicio, 'days'));
      const diferencia = fin.diff(inicio, "days") + 1;
      const resultado = diferencia - this.reservableDay.length;
      // console.log(resultado);
      if (resultado === 1) {
        return `${resultado} día del rango de fecha que seleccionaste, no está disponible para este lugar`;
      }
      if (resultado === 0) {
        return false;
      }
      if (
        diferencia < this.reservableDay.length ||
        diferencia > this.reservableDay.length
      ) {
        return `${resultado} días del rango de fecha que seleccionaste, no están disponibles para este lugar`;
      }
      return true;
    },

    canNext(fromValid) {
      if (this.payment_method === "") return false;
      if (!this.terms) return false;
      if (!this.rules) return false;
      if (!this.monto >= 10000) return false;

      if (this.payment_method === "CARD") {
        return fromValid;
      }

      // valid name and email ?
      if (this.card.nameUser === "") return false;
      if (this.card.email === "") return false;

      return true;
    },
    setPaymentMethod(np) {
      this.payment_method = np;
    },

    formatDate(dateStr) {
      const splt = dateStr.split("-");
      return `${splt[2]}/${splt[1]}/${splt[0]}`;
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    procesarTarjeta() {
      this.isLoading = true;

      // eslint-disable-next-line no-undef
      Conekta.setPublicKey(CONEKTA_KEY);

      const vue = this;

      if (this.payment_method === "CARD") {
        // eslint-disable-next-line no-undef
        Conekta.Token.create(
          {
            card: this.card,
          },
          this.handlerCardToken,
          (err) => {
            this.error.push(err);
            vue.isLoading = false;
          }
        );
      } else {
        vue.makeReservation({
          id: vue.$route.query.id,
          type: vue.$route.query.type,
          email: vue.card.email.trim(),
          name: vue.card.nameUser,
          payment_method: vue.payment_method,
          countryCode: vue.validatePhone.countryCode,
          number: vue.validatePhone.nationalNumber,
          countryCallingCode: vue.validatePhone.countryCallingCode,
          loading() {
            vue.isLoading = false;
          },
        });
      }
    },

    handlerCardToken(response) {
      this.card.tokentarjeta = response.id;
      const vue = this;

      this.card.callback = function makeReservationCallback() {
        vue.makeReservation({
          id: vue.$route.query.id,
          type: vue.$route.query.type,
          email: vue.card.email.trim(),
          name: vue.card.nameUser,
          payment_method: vue.payment_method,
          number: vue.validatePhone.nationalNumber,
          countryCode: vue.validatePhone.countryCode,
          countryCallingCode: vue.validatePhone.countryCallingCode,
          loading() {
            vue.isLoading = false;
          },
        });
      };

      this.card.error_callback = function makeReservationErrorCallback() {
        this.isLoading = false;
      };

      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('Se generó un error');
      } else {
        // console.log('Se enviaron los datos Correctamente! Desde el front');
        this.card.phone = {
          number: this.card.phone.number,
          countryCode: this.validatePhone.countryCode,
          formattedNumber: this.validatePhone.formattedNumber,
          countryCallingCode: this.validatePhone.countryCallingCode,
          nationalNumber: this.validatePhone.nationalNumber,
        },
        // console.log('card: ', this.card);
        this.enviarTarjeta({ card: this.card, loader: this.loading });
      }
    },
  },
  created() {
    this.diasReserva({
      id: this.$route.query.id,
      type: this.$route.query.type,
      start: this.$route.query.start,
      end: this.$route.query.end,
    });

    this.updateProfile();
    this.cargarLocalStorage();

    if (this.usuario != null) {
      this.tmp_number = this.usuario.phone;
    }
  },
  validations: {
    card: {
      nameUser: {
        required,
        isNameValid: helpers.regex("isNameValid", /^[a-zA-Zá-ÿ_ ]*$/i),
        maxLength: maxLength(120),
      },
      email: {
        email,
        required,
      },
      name: {
        required,
        isNameValid: helpers.regex("isNameValid", /^[a-zA-Zá-ÿ_ ]*$/i),
        maxLength: maxLength(120),
      },
      number: {
        required,
        minLength: minLength(19),
      },
      exp_month: {
        required,
      },
      exp_year: {
        required,
      },
      cvc: {
        required,
      },
    },
    terms: {
      accepted: (val) => val === true,
    },
    rules: {
      accepted: (val) => val === true,
    },
  },
};
</script>

<style>

.pet-friendly-chk{
  width: 10%;
  float:right;
  margin-right: 12%;
}
.pet-friendly-bml{
  width: 10%;
  float:right;
  margin-right: 12%;
}
.pet-friendly-bml-chk{
  width: 25%;
  display: block;
  margin: 0 auto;
}
.pet-friendly-checkout{
  width: 7%;
}
#loading {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}
#spinner {
  display: block;
  margin: 10vh auto;
  left: 40%;
}
.uk-button-secondary {
  color: #fff;
}
.nopad {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
@keyframes ldio-8ki3ixd6igm {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-8ki3ixd6igm div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-8ki3ixd6igm linear 1s infinite;
  background: #1a5655;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}
.ldio-8ki3ixd6igm div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #1a5655;
}
.ldio-8ki3ixd6igm div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #1a5655;
}
.loadingio-spinner-spinner-9bw4bi8wdp {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-8ki3ixd6igm {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-8ki3ixd6igm div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
.qut {
  text-decoration: none !important;
}
.center {
  text-align: center !important;
}
/* input.uk-checkbox {
background-color: red;
} */
input.uk-checkbox:checked {
  background-color: #1b4225;
}
input.uk-checkbox:checked:focus {
  background-color: #1b4225;
}
.abajo {
  margin-top: 50vh;
}

.payment-method {
  text-align: center;
  padding: 25px !important;
  vertical-align: middle;
  background-color: rgba(211, 211, 211, 0.6);
  margin: 0px !important;
  cursor: pointer;
}

.payment-method:hover {
  transition: 300ms;
  background-color: rgba(27, 66, 37, 0.6);
}

.payment-method.selected {
  background-color: rgba(27, 66, 37, 1);
}

.payment-method > h4 {
  margin: 0 !important;
}

.payment-method:hover > h4 {
  margin: 0 !important;
  color: white !important;
}

.payment-method.selected > h4 {
  margin: 0 !important;
  color: white !important;
}

.payment-content {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 150px;
}

label {
  font-weight: 600;
}
</style>
