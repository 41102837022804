<template>
<div class="uk-container">
    <div
      class="uk-position-center uk-card uk-card-default uk-card-body uk-width-1-2@m"
    >
      <h3 class="uk-card-title">Iniciar Sesión</h3>
        <hr>
        <LoginForm @iniciar="sesion" />

      <div class="uk-text-center">
        <span>
          ¿No tienes cuenta?
          <a href=""> </a>
          <router-link to="/registro">Crea tu cuenta en menos de 1 minuto.</router-link>
        </span>
      </div>

      <div class="uk-margin-small-bottom uk-text-center uk-margin-small-top" >
        <span class="">
          <a href=""> </a>
          <router-link to="/forget-password">¿Olvidaste tú contraseña?</router-link>
        </span>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import LoginForm from '../components/LoginForm.vue';

export default {
  watch: {
  },
  data() {
    return {
    };
  },
  components: {
    LoginForm,
  },

  methods: {
    ...mapActions(['iniciarSesion']),
    sesion(sesion) {
      // console.log(sesion);
      this.iniciarSesion(sesion);
    },
  },
};
</script>

<style>

</style>
