<template>
  <form @submit.prevent="sesion" class="uk-form-stacked">
    <div class="uk-margin">
      <!-- eslint-disable-next-line max-len -->
      <label class="uk-form-label uk-text-left" for="form-stacked-email"><span class="uk-text-danger" v-if="!$v.usuario.email.required">*</span> Email</label>
      <div class="uk-form-controls">
        <input
          class="uk-input uk-form-width-large"
          :class="{ 'uk-form-danger': $v.usuario.email.$error }"
          id="form-stacked-email"
          type="email"
          placeholder=""
          v-model.trim="$v.usuario.email.$model"
        />
        <div class="uk-alert-danger" uk-alert v-if="!$v.usuario.email.email">
          <a class="uk-alert-close" uk-close></a>
          <span>
            Debe ingresar una dirección de correo válida
          </span>
        </div>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label uk-text-left" for="form-stacked-password">
        <span class="uk-text-danger" v-if="!$v.usuario.password.required">*</span> Contraseña
      </label>
      <div class="uk-form-controls">
        <input
          class="uk-input uk-form-width-large"
          :class="{ 'uk-form-danger': !$v.usuario.password.minLength }"
          id="form-stacked-password"
          type="password"
          placeholder=""
          v-model.trim="$v.usuario.password.$model"
        />
        <div class="uk-alert-danger" uk-alert v-if="!$v.usuario.password.minLength">
          <a class="uk-alert-close" uk-close></a>
          <span>
            Debe tener mínimo 6 caracteres
          </span>
        </div>
      </div>

      <div class="uk-margin uk-text-center">
        <small class="uk-text-danger" v-if="$v.$invalid">Los campos con * son obligatorios</small>
      </div>

      <div class="uk-alert-danger uk-text-center" uk-alert v-if="errorsLogin.tipo !== null">
          <span>
            {{errorsLogin.mensaje}}
          </span>
      </div>

      <div class="uk-text-center" v-if="loading">
        <dot-loader :loading="loading"></dot-loader>
      </div>

      <!-- eslint-disable-next-line max-len -->
      <button
        class="uk-button uk-align-center app-bg uk-margin-small-top uk-margin-small-bottom"
        :disabled="$v.$invalid"
      >
        Ingresar
      </button>
    </div>
  </form>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  required,
  email,
  minLength,
} from 'vuelidate/lib/validators';
import DotLoader from 'vue-spinner/src/PulseLoader.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DotLoader,
  },
  data() {
    return {
      usuario: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapState(['errorsLogin', 'loading']),
  },
  methods: {
    ...mapActions(['limpiarErrores']),
    sesion() {
      console.log('submit!');
      this.$v.$touch();
      if (this.$v.$invalid) {
        // this.submitStatus = 'ERROR';
        console.log('Se generó un error');
      } else {
        console.log('Se enviaron los datos Correctamente!');
        this.$emit('iniciar', this.usuario);
      }
    },

  },

  validations: {
    usuario: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },

  created() {
    this.limpiarErrores();
  },
};
</script>

<style></style>
