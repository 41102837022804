<template>
  <!-- eslint-disable vue/return-in-computed-property
 eslint-disable consistent-return  -->
  <div class="uk-container">
    <div class="uk-position-center uk-card uk-card-default uk-card-body uk-width-1-2@m">
      <h3 class="uk-card-title">Restablecer Contraseña</h3>
      <hr />
      <form @submit.prevent="updatePassword" class="uk-form-stacked">
        <div class="uk-margin">
          <label class="uk-form-label uk-text-left" for="form-stacked-password">Contraseña</label>
          <div class="uk-form-controls">
            <input
              class="uk-input uk-form-width-large"
              id="form-stacked-password"
              type="password"
              placeholder=""
              v-model.trim="$v.newPass.password.$model"
              :class="{ 'uk-form-danger': $v.newPass.password.$error }"
            />
            <div>
              <!-- eslint-disable-next-line max-len -->
              <span class="uk-text-danger" v-if="!this.$v.newPass.password.minLength"
                >Mínimo 6 Caracteres</span
              >
            </div>
          </div>
          <!-- {{$v.newPass.password}} -->
        </div>
        <div class="uk-margin">
          <!-- eslint-disable-next-line max-len -->
          <label class="uk-form-label uk-text-left" for="form-stacked-password2"
            >Repite la contraseña</label
          >
          <div class="uk-form-controls">
            <input
              class="uk-input uk-form-width-large"
              id="form-stacked-password2"
              type="password"
              placeholder=""
              v-model.trim="$v.newPass.password2.$model"
              :class="{ 'uk-form-danger': $v.newPass.password2.$error }"
            />
            <div>
              <!-- eslint-disable-next-line max-len -->
              <span class="uk-text-danger" v-if="!$v.newPass.password2.sameAsPassword"
                >Las contraseñas deben coincidir</span
              >
            </div>
          </div>
          <!-- {{$v.newPass.password2}} -->
          <div class="uk-alert-danger" uk-alert v-for="item in errors" :key="item.id">
            <span>{{ item.message }}</span>
          </div>
        </div>
        <div class="uk-alert-success" uk-alert v-for="item in success" :key="item.id">
          <p>
            {{ item }}
          </p>
        </div>
        <!-- eslint-disable-next-line max-len -->
        <button
          class="uk-button uk-align-center app-bg uk-margin-medium-top uk-margin-small-bottom"
          :disabled="$v.$invalid"
        >
          Actualizar Contraseña
        </button>
        <div></div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  required,
  sameAs,
  minLength,
} from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      newPass: {
        password: '',
        password2: '',
      },
    };
  },

  computed: {
    ...mapState(['success', 'errors']),
  },

  methods: {
    ...mapActions(['updatePass']),
    // eslint-disable-next-line consistent-return
    updatePassword() {
      console.log(this.newPass);
      console.log(this.$route.query.token);
      this.updatePass({
        password: this.newPass,
        token: this.$route.query.token,
      });
    },
  },

  validations: {
    newPass: {
      password: {
        required,
        minLength: minLength(6),
      },
      password2: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
};
</script>

<style></style>
