<template>
  <div class="uk-container">
    <div class="uk-margin-medium-top">
      <div class="uk-card uk-card-default uk-card-hover uk-card-body">
        <h3 class="uk-card-title uk-text-center">Términos Y Condiciones</h3>
        <div class="uk-margin-medium-top">
          <p>
            <strong>
              KANAJ Bubble Glamping
            </strong>
          </p>

          <p>El Horario De Check In Será Después De Las 3:00pm.</p>

          <p>
            El Horario De Check Out Será Antes De Las 12:00pm. Pedimos De Favor Ser Respetuosos Con
            Los Horarios De Check Out Ya Que Se Debe De Realizar Limpieza y Sanitización Del Área De
            Burbujas.
          </p>

          <p>
            En Caso Que No Realicen El Check Out En Los Horarios Correspondientes, El Huésped Se
            Compromete A Pagar La Cantidad De $1,000 Pesos Por Hora Extra, Teniendo Como Máximo 1:00
            pm Para Realizar El Check Out.
          </p>

          <p class="uk-text-danger">*El costo de la reserva es No reembolsable</p>

          <p class="uk-text-danger">
            <b>
              <!-- eslint-disable-next-line max-len -->
              Si cancelas tu reservación, no obtendrás ningún reembolso o crédito para usar en una estancia futura.
            </b>
          </p>

          <p>
            <!-- eslint-disable-next-line max-len -->
            Cambios de fechas sin costo, se permiten por lluvias prolongadas de más de 2 días o bajas
            en la temperatura a menos de 0 grados o huracán. Los cambios solo se aceptaran 2 dias
            antes de la fecha de reserva.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
